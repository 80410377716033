import { createStore } from 'vuex'
import { account } from './account'
import { config } from './config'
import { contacts } from './contacts'
import { system_status } from './system_status'

export default createStore({
  modules: {
    account, config, contacts, system_status
  }
})
