import api from '@/utils/api';
import { dateIsoUtcToEst } from '@/utils/helpers';
import momentTz from 'moment-timezone';

export enum PossibleSendingTaskStatuses {
  ACTIVE = 'ACTIVE',
  STOPPED = 'STOPPED',
}

export const contacts = {
  state: {
    contactsImportsList: [],
    sendingTasksStats: {},
    sentMessages: {},
    savedInputs: {},
    savedInputsById: {},
  },
  getters: {
    getContactsImports: (state: any) => state.contactsImportsList,
    getSaved_prompt_prefix(state: any) {
      return state.savedInputs['prompt_prefix'] || [];
    },
    getSaved_message_template(state: any) {
      return state.savedInputs['message_template'] || [];
    },
    getSavedById: (state: any) => (id: number) => state.savedInputsById[id],
    getSendingTaskStats: (state: any) => (id: number) => {
      if (!state.sendingTasksStats[id]) {
        return null;
      }

      const data = { ...state.sendingTasksStats[id] };
      if (data.latestSentMessage && data.latestSentMessage.sentAt) {
        data.latestSentMessage.sentAtString = dateIsoUtcToEst(data.latestSentMessage.sentAt);
      }

      if (data.sendingTask && data.sendingTask.startAtTime) {
        data.sendingTask.startAtTimeString = dateIsoUtcToEst((new Date).toISOString().split('T')[0] + 'T' + data.sendingTask.startAtTime, 'HH:mm:ss');
      }

      if (data.sendingTask && data.sendingTask.stopAtTime) {
        data.sendingTask.stopAtTimeString = dateIsoUtcToEst((new Date).toISOString().split('T')[0] + 'T' + data.sendingTask.stopAtTime, 'HH:mm:ss');
      }

      return data;
    },
    getCompiledMessagesList: (state: any) => (id: number, with_replies_only: number) => state.sentMessages?.[id]?.[with_replies_only] ?? null,
  },
  mutations: {
    SET_CONTACTS_IMPORTS_LIST(state: any, value: any[]) {
      state.contactsImportsList = value;
    },
    SET_SENDING_TASKS_STATS_LIST(state: any, data: { id: number, stats: any }) {
      state.sendingTasksStats[data.id] = data.stats;
    },
    SET_MESSAGES_LIST(state: any, data: { task_id: number, messages: string, with_replies_only: number }) {
      if (!state.sentMessages[data.task_id]) {
        state.sentMessages[data.task_id] = {};
      }
      state.sentMessages[data.task_id][data.with_replies_only] = data.messages;
    },
    LOAD_SAVED_INPUTS(state: any, data: { id: number, type: string, short_name: string, field1: string }[]) {
      state.savedInputsById = {};
      state.savedInputs = {};

      for (const record of data) {
        if (!state.savedInputs[record.type]) {
          state.savedInputs[record.type] = [];
        }
        state.savedInputs[record.type].push(record);
        state.savedInputsById[record.id] = record;
      }
    },
  },
  actions: {
    loadAllContactsImportsList(context: any) {
      return api({ url: '/contacts/my-imports', data: {}, method: 'GET' })
        .then((response: any) => {
          if (response.data.list) {
            context.commit('SET_CONTACTS_IMPORTS_LIST', response.data.list);
            return true;
          }
        })
    },
    loadSendingTaskStats(context: any, payload: { id: number }) {
      return api({ url: '/sender/task', params: { import_file_id: payload.id }, method: 'GET' })
        .then((response: any) => {
          if (response.data) {
            context.commit('SET_SENDING_TASKS_STATS_LIST', { id: payload.id, stats: response.data });
            return true;
          }
        })
    },
    sendTestMessage(
      context: any,
      payload: {
        phone_number: string,
        message_template: string,
        replace_any_link_in_bots_response: string,
      }
    ) {
      return api({ url: '/sender/test-message', data: payload, method: 'POST' })
        .then((response: any) => {
          // if (response.data) {
          // }
        })
    },
    loadTestContact(
      context: any,
      payload: {
        phone_number_with_country_code: string,
        import_name: string,
        first_name: string,
        last_name: string,
      }
    ) {
      return api({ url: '/contacts/import-contact', data: payload, method: 'POST' })
        .then((response: any) => {
          return response.data;
          // if (response.data) {
          // }
        })
    },
    createSendingTask(
      context: any,
      payload: {
        contacts_list_import_file: number,
        message_template: string,
        prompt_prefix: string,
        timeout_seconds: number,

        startAtTime: string,
        stopAtTime: string,

        replace_any_link_in_bots_response: string,
      }
    ) {
      return api({ url: '/sender/task', data: payload, method: 'POST' })
        .then((response: any) => {
          // if (response.data) {
          // }
        })
    },
    updateSendingTask(
      context: any,
      payload: {
        id: number,
        status: PossibleSendingTaskStatuses,
      }
    ) {
      return api({ url: '/sender/task?id=' + payload.id, data: payload, method: 'PATCH' })
        .then((response: any) => {
          // if (response.data) {
          // }
        })
    },
    loadMessagesLog(context: any, payload: { task_id: number, with_replies_only: number }) {
      return api({ url: '/sender/messages-compiled', params: { task_id: payload.task_id, with_replies_only: payload.with_replies_only ?? 0 }, method: 'GET' })
        .then((response: any) => {
          context.commit('SET_MESSAGES_LIST', { task_id: payload.task_id, messages: response.data, with_replies_only: payload.with_replies_only });
          return true;
        })
    },
    loadSavedInputs(context: any, payload: any) {
      return api({ url: '/sender/saved-inputs', params: {}, method: 'GET' })
        .then((response: any) => {
          context.commit('LOAD_SAVED_INPUTS', response.data.list);
          return true;
        })
    },
    saveInputs(context: any, payload: { message_template: string, prompt_prefix: string, message_template_id: number, prompt_prefix_id: number }) {
      return api({ url: '/sender/saved-inputs', data: payload, method: 'PATCH' })
        .then((response: any) => {
          return true;
        })
    },
  },
  modules: {
  }
}