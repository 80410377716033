
import { defineComponent } from 'vue';
import api, { authAbortSignal, storage } from './utils/api';
import { checkIsAuthRequired } from './router';
import { eventEmitter } from './utils/helpers';

let tokenInterval: any = null;
let updating = false;
let configUpdating = false;

export default defineComponent({
  components: {
    
  },
  mounted() {
    if (tokenInterval) {
      clearInterval(tokenInterval);
    }
    this.refreshToken();
    tokenInterval = setInterval(() => this.refreshToken(), 60000);
    configUpdating = true;
  },
  beforeUnmount() {
    configUpdating = false;
    eventEmitter.removeAllListeners();
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    }
  },
  methods: {
    refreshToken() {
      if (updating || !storage.isLoggedIn()) {
        (this as any).$store.dispatch('setIsLoggedIn');
        return;
      }
      updating = true;
      api({ url: '/auth/refresh-token', method: 'POST', signal: authAbortSignal }).then(() => {
        (this as any).$store.dispatch('setIsLoggedIn');
      }).finally(() => {
        updating = false;
      })
    },
  },
  watch: {
    isLoggedIn(val) {
      if (!val) {
        if (checkIsAuthRequired(this.$route.name)) {
          this.$router.replace({ name: 'LoginPage' });
        }
      }
    }
  }
})

