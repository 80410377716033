import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// eslint-disable-next-line
// @ts-ignore
import VueClipboard from 'vue3-clipboard'

import { defineRule } from 'vee-validate';
// eslint-disable-next-line
// @ts-ignore
import Toaster from '@meforma/vue-toaster';

defineRule('required', (value: any) => {
    if (!value || !value.length) {
        return 'This field is required';
    }
    return true;
});
defineRule('email', (value: any) => {
    // Field is empty, should pass
    if (!value || !value.length) {
        return true;
    }
    // Check if email

    // eslint-disable-next-line no-useless-escape
    if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) {
        return 'This field must be a valid email';
    }
    return true;
});
defineRule('min', (value: any, [min]: [number]) => {
    if (Number(value) < Number(min)) {
        return 'This field should be >= ' + min.toString();
    }
    return true;
})
defineRule('max', (value: any, [max]: [number]) => {
    if (Number(value) > Number(max)) {
        return 'This field should be <= ' + max.toString();
    }
    return true;
})
defineRule('between', (value: any, [min, max]: [number, number]) => {
    if (Number(value) > Number(max) || Number(value) < Number(min)) {
        return 'This field should be <= ' + max.toString() + ' and >=' + min.toString();
    }
    return true;
})
createApp(App)
.use(store)
.use(router)
.use(Toaster, { position: "top" })
.use(VueClipboard, {
    autoSetContainer: true,
    appendToBody: true,
}).mount('#app')
