import api from '@/utils/api';
import { dateIsoUtcToEst } from '@/utils/helpers';

export const system_status = {
  state: {
    latestHeartbeat: null,
    latestSuccessHeartbeat: null,
  },
  getters: {
    getLatestHeartbeat: (state: any) => {
      if (!state.latestHeartbeat) {
        return null;
      }
      const data = { ...state.latestHeartbeat };

      if (data.sentAt) {
        data.sentAtString = dateIsoUtcToEst(data.sentAt);
      }

      if (data.receivedAt) {
        data.receivedAtString = dateIsoUtcToEst(data.receivedAt);
      }
      return data;
    },
    getLatestSuccessHeartbeat: (state: any) => {
      if (!state.latestSuccessHeartbeat) {
        return null;
      }
      const data = { ...state.latestSuccessHeartbeat };

      if (data.sentAt) {
        data.sentAtString = dateIsoUtcToEst(data.sentAt);
      }

      if (data.receivedAt) {
        data.receivedAtString = dateIsoUtcToEst(data.receivedAt);
      }

      if (data.timeAfterSending) {
        const minutes = Math.floor(data.timeAfterSending / 1000 / 60);
        if (minutes > 0) {
          data.timeAfterSendingString = `${minutes} min.`;
        } else {
          const seconds = Math.floor(data.timeAfterSending / 1000);
          data.timeAfterSendingString = `${seconds} sec.`;
        }
      }

      return data;
    },
  },
  mutations: {
    SET_HEARTBEATS_DATA(
      state: any,
      value: {
        latestHeartbeat: { sentAt: string, receivedAt: string, status_label: string, isWarn: boolean, isOk: boolean },
        latestSuccessHeartbeat: { sentAt: string, receivedAt: string, status_label: string, isOk: boolean }
      }
    ) {
      state.latestHeartbeat = value.latestHeartbeat;
      state.latestSuccessHeartbeat = value.latestSuccessHeartbeat;
    }
  },
  actions: {
    loadheartbeatsData(context: any) {
      return api({ url: '/sender/heartbeat', data: {}, method: 'GET' })
        .then((response: any) => {
          if (response.data) {
            context.commit('SET_HEARTBEATS_DATA', response.data);
            return true;
          }
        })

    }
  },
  modules: {
  }
}