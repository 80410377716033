import { EventEmitter } from "events";
import momentTz from "moment-timezone";

export const currencyFormatter = (currency: any) => {
    currency.iso_link = currency.iso + (currency.network ? '_' + currency.network: '');
}

export const eventEmitter = new EventEmitter();


export const dateIsoUtcToEst = (inputIso: string, formatOut = 'YYYY-MM-DD HH:mm') => {
    if (!inputIso) {
        return '';
    }
    return momentTz.tz(inputIso, 'UTC').clone().tz('America/New_York').format(formatOut)

}

export const saveBlob = (function () {
    const a = document.createElement("a") as any;
    document.body.appendChild(a);
    a.style = "display: none";
    return function (blob:any, fileName:any) {
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    };
}());
