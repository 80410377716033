import axios from 'axios';
import router from '../router/index';
import store from '../store/index';
import vuex from '../store/index';

const tkN = 'tk';

const abort = new AbortController();

export const authAbortSignal = abort.signal;

export const storage = {
  getToken() {
    return localStorage.getItem(tkN);
  },
  setToken(token: string) {
    localStorage.setItem(tkN, token)
  },
  removeToken() {
    localStorage.removeItem(tkN);
  },
  isLoggedIn() {
    return !!this.getToken();
  },
};

export const isLoggedIn = () => storage.isLoggedIn();
export const logOut = () => { storage.removeToken(); abort.abort(); };

function onFullfilled(response: any) {
  const authorization = response.data.token;
  if (authorization) {
    storage.setToken(authorization);
  }
  return response;
}

function onRejected(err: any) {
  if (err.response && err.response.status === 500) {
    // {data:err.response.data, url:err.response.config.url, method:err.response.config.method}
    // vuex.commit('INTERNAL_ERROR', true)
  }

  if (err.response && err.response.status === 409) {
    console.log('Back-end maintenance...');
  }

  if (err.response && err.response.status === 401) {
    store.dispatch('logout');
    // storage.removeToken();
    // router.push('/');
  }


  if (err.response && err.response.status === 402) {
    alert('402 error');
  }

  return Promise.reject(err.response);
}

function onRequestError(error: any) {
  return Promise.reject(error);
}

function getRndInteger(min: number, max: number) {
  return Math.floor(Math.random() * (max - min)) + min;
}

const privatePath = process.env.VUE_APP_API_PATH;

const configure = (axios: any) => {
  axios.defaults.baseURL = `${privatePath}`;
  // axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';

  axios.interceptors.request.use((config: any) => {
    if (storage.isLoggedIn()) {
      config.headers['Authorization'] = 'Bearer ' + storage.getToken();
    }
    if (!config.data) {
      config.data = {};
    }
    config.data['project_id'] = +process.env.VUE_APP_PROJECT_ID;
    return config;
  }, onRequestError);

  axios.interceptors.response.use(onFullfilled, onRejected);

  // axios.interceptors.response.use(function (response) {
  //   // Do something with response data
  //   return response;
  // }, function (error) {
  //   // Do something with response error
  //   console.log("aaaaaaaaaaaaaaaaaaaaaa",error.message);
  //   return Promise.reject(error);
  // });

  return axios;
};
export default configure(axios);