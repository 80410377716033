import { storage } from '@/utils/api';
import { createRouter, createWebHistory } from 'vue-router'

const onlyAuth: string[] = [
  'ContactsListImport',
];

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import(/* webpackChunkName: "MainView" */ '../views/MainView.vue'),
    children: [
      {
        path: '',
        name: 'Landing',
        component: () => import('../pages/Landing/LandingPage.vue')
      },
      {
        path: '/login',
        name: 'Login',
        children: [
          {
            path: '',
            name: 'LoginPage',
            component: () => import(/* webpackChunkName: "LoginPage" */ '../pages/Login/LoginPage.vue'),
          }
        ]
      },
      {
        path: '/system',
        name: 'SystemPage',
        children: [
          {
            path: '',
            name: 'SystemStatusPage',
            component: () => import(/* webpackChunkName: "SystemStatusPage" */ '../pages/SystemPage/SystemStatusPage.vue'),
          }
        ]
      },
      {
        path: '/contacts-list/import',
        name: 'ContactsList',
        children: [
          {
            path: '',
            name: 'ContactsListImport',
            component: () => import(/* webpackChunkName: "ContactsListImport" */ '../pages/ContactsList/ImportPage.vue'),
          }
        ]
      },
      {
        path: '/reset-password',
        name: 'ResetPassword',
        children: [
          {
            path: '',
            name: 'ResetPasswordPage',
            component: () => import(/* webpackChunkName: "ResetPasswordPage" */ '../pages/Login/ResetPasswordPage.vue'),
          }
        ]
      },
      {
        path: '/signup/:inputStep?',
        name: 'Register',
        props: true,
        children: [
          {
            path: '',
            name: 'RegisterPage',
            props: true,
            component: () => import(/* webpackChunkName: "RegisterPage" */ '../pages/Register/RegisterPage.vue'),
          }
        ]
      },
    ]
  },
  {
    path: '/confirmation-code/:type/:payload',
    name: 'ConfirmationCode',
    component: () => import(/* webpackChunkName: "ConfirmationCode" */ '../pages/ConfirmationCode.vue'),
    props: true,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const routeName = to.name;
  if (!routeName || typeof routeName !== 'string') return next();
  if (checkIsAuthRequired(routeName) && !storage.isLoggedIn()) return next({ name: 'LoginPage' })
  if (routeName === 'Landing' && storage.isLoggedIn()) return next({ name: 'ContactsListImport' })
  return next();
})


export default router

export const checkIsAuthRequired = (routeName: string | symbol | undefined | null) => {
  if (!routeName || typeof routeName !== 'string') return;
  return onlyAuth.includes(routeName);
}