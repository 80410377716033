export const config = {
  state: {
    current_ticker_id: null,
  },
  getters: {
    getCurrentTickerId(state:any) {
      return state.current_ticker_id;
    },
    getCurrentTicker(state:any, getters:any, _:any, rootGetters:any) {
      if (!state.current_ticker_id) return null;
      return rootGetters.getPossibleTickers[state.current_ticker_id];
    }
  },
  mutations: {
    UPDATE_CURRENT_TICKER(state:any, data:any) {
      state.current_ticker_id = data;
    },
  },
  actions: {
    setCurrentTicker(context:any, payload: { id: number }){
      context.commit('UPDATE_CURRENT_TICKER', payload.id);
    }
  },
  modules: {
  }
}